import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import Login from './Login';
import Chat from './Chat';
import Layout from './Layout';
import LoadingSpinner from './LoadingSpinner';
import stripePromise from '../lib/stripe';
import ResetPassword from './ResetPassword';
import { callFunction } from '../lib/functionCaller';
import MidiBackground from './MidiBackground';
import OnboardingCarousel from './OnboardingCarousel';

const subActive = (user) => {
  return user.has_active_subscription && ( user.subscription_end_date ? new Date(user.subscription_end_date) > new Date() : true );
};

function AppContent() {
  const { user, loading: authLoading, signOut } = useAuth();
  const [credits, setCredits] = useState(0);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [creditsLoading, setCreditsLoading] = useState(true);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true); // New state
  const [isResetPasswordFlow, setIsResetPasswordFlow] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const fetchUserProfile = useCallback(async () => {
    if (user) {
      setCreditsLoading(true);
      setSubscriptionLoading(true); // Set subscription loading to true
      try {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('credits, has_active_subscription, subscription_end_date, cancel_at_period_end, has_completed_onboarding')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        setCredits(data.credits);
        setHasActiveSubscription(data.has_active_subscription);
        setIsSubscriptionActive(subActive(data));
        setCancelAtPeriodEnd(data.cancel_at_period_end);
        setHasCompletedOnboarding(data.has_completed_onboarding);
        
        // Show onboarding if user hasn't completed it
        if (!data.has_completed_onboarding) {
          setShowOnboarding(true);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setCreditsLoading(false);
        setSubscriptionLoading(false); // Set subscription loading to false
      }
    } else {
      setCreditsLoading(false);
      setSubscriptionLoading(false); // Set subscription loading to false
    }
  }, [user]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);
  const handleOnboardingComplete = async () => {
    setShowOnboarding(false);
    if (user) {
      try {
        const { data, error } = await supabase.rpc('update_onboarding_status', { user_id: user.id });
        if (error) throw error;
        setHasCompletedOnboarding(true);
      } catch (error) {
        console.error('Error in handleOnboardingComplete:', error);
      }
    } else {
      console.error('No user found when trying to complete onboarding');
    }
  };

  const updateCredits = useCallback((newCredits) => {
    setCredits(newCredits);
  }, []);

  const handleManageBilling = async () => {
    try {
      const data = await callFunction('create-billing-portal-session', { userId: user.id });
      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating billing portal session');
      throw error; // Rethrow the error to be caught in the Layout component
    }
  };

  const handleSubscription = async () => {
    if (hasActiveSubscription) {
      // If user already has a subscription, redirect to billing portal
      return handleManageBilling();
    } else {
      // Otherwise, create a new subscription
      const stripe = await stripePromise;
      try {
        const data = await callFunction('create-checkout-session', { userId: user.id });
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error; // Rethrow the error to be caught in the Layout component
      }
    }
  };

  const handleTopUpPurchase = async (credits) => {
    if (!user) {
      console.error('User not logged in');
      return;
    }

    const stripe = await stripePromise;
    try {
      const data = await callFunction('create-topup-session', { 
        userId: user.id,
        credits: credits
      });
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating top-up session:', error);
      // You might want to show an error message to the user here
    }
  };

  if (authLoading) {
    return <LoadingSpinner />;
  }
  
  return (
    <div className="full-viewport-height flex flex-col overflow-hidden">
      <MidiBackground isApp={true}/>
      <div className="relative z-10 flex-grow flex flex-col overflow-hidden">
        {isResetPasswordFlow ? (
          <ResetPassword onResetComplete={() => {
            setIsResetPasswordFlow(false);
            signOut();
          }} />
        ) : user ? (
          <Layout 
            user={user} 
            credits={credits} 
            creditsLoading={creditsLoading}
            onPurchaseSubscription={handleSubscription} 
            onManageBilling={handleManageBilling}
            isResetPasswordFlow={isResetPasswordFlow}
            isSubscriptionActive={isSubscriptionActive}
            cancelAtPeriodEnd={cancelAtPeriodEnd}
            setShowOnboarding={setShowOnboarding}
            subscriptionLoading={subscriptionLoading}
            updateCredits={updateCredits}
            onTopUpPurchase={handleTopUpPurchase}
          />
        ) : (
          <Login />
        )}
      </div>
      {showOnboarding && (
        <OnboardingCarousel onClose={handleOnboardingComplete} />
      )}
    </div>
  );
}

export default AppContent;
