import React, { useEffect, useState } from 'react';
import { CreditCard, X } from 'lucide-react'; // Make sure you have lucide-react installed
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "./ui/card"
import { Label } from "./ui/label"
import { logEvent } from '../lib/amplitude';

function OutOfCreditsDialog({ isOpen, onClose, onPurchase, onTopUp, isSubscriptionActive }) {
  const [selectedTopUp, setSelectedTopUp] = useState(null);

  const topUpOptions = [
    { credits: 50, price: 9.99 },
  ];

  useEffect(() => {
    if (isOpen) {
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive });
    }
  }, [isOpen, isSubscriptionActive]);

  if (!isOpen) return null;

  const title = isSubscriptionActive ? "Out of Credits" : "Subscribe to Get Credits";
  const message = isSubscriptionActive
    ? "You've run out of credits. Top up now to continue using MidiMate, or wait for your credits to replenish on your next subscription renewal date."
    : "You've run out of credits. Subscribe to get monthly credits, automatically replenished every billing cycle.";
    const buttonText = isSubscriptionActive ? "Manage Billing" : "Subscribe";

  const handleClose = () => {
    logEvent('Out of Credits Dialog Closed', { isSubscriptionActive });
    onClose();
  };

  const handlePurchase = () => {
    if (isSubscriptionActive && selectedTopUp) {
      logEvent('Top Up Credits Clicked', { credits: selectedTopUp.credits, price: selectedTopUp.price });
      onTopUp(selectedTopUp.credits);
    } else {
      logEvent('Out of Credits Dialog Purchase Clicked', { isSubscriptionActive });
      onPurchase();
    }
  };

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>{title}</span>
            <Button variant="ghost" size="icon" onClick={handleClose}>
              <X className="h-4 w-4" />
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <CreditCard className="h-6 w-6 text-primary" />
            </div>
            <p className="text-muted-foreground flex-1">
              {message}
            </p>
          </div>
          {isSubscriptionActive && (
            <div className="mt-4">
              <h3 className="font-semibold mb-2">Top Up Credits</h3>
              <div className="space-y-2">
                {topUpOptions.map((option, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="radio"
                      id={`option-${index}`}
                      name="topUpOption"
                      value={index}
                      checked={selectedTopUp === option}
                      onChange={() => setSelectedTopUp(option)}
                      className="mr-2 h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                    />
                    <Label htmlFor={`option-${index}`} className="flex items-center cursor-pointer">
                      <span className="ml-2">{option.credits} credits for ${option.price}</span>
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex justify-end space-x-2">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            onClick={handlePurchase} 
            disabled={isSubscriptionActive && !selectedTopUp}
            className="bg-[#f05477] hover:bg-[#d03357] text-white transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500"
          >
            {isSubscriptionActive ? (selectedTopUp ? `Buy ${selectedTopUp.credits} Credits` : 'Select an option') : buttonText}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default OutOfCreditsDialog;