// src/components/Layout.js
import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Menu, LogOut, CreditCard, Zap, Settings, Bot, HelpCircle, Loader2, FileText, Shield, Mail, AlertCircle, X, MessageSquarePlus, Info, PhoneCall, Music, Mic } from 'lucide-react'; // Import HelpCircle icon
import OnboardingCarousel from './OnboardingCarousel'; // Import OnboardingCarousel
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import FeedbackWidget from './FeedbackWidget';
import { useAuth } from '../contexts/AuthContext';
import OutOfCreditsDialog from './OutOfCreditsDialog';
import { logEvent } from '../lib/amplitude';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { useNavigate } from 'react-router-dom';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./ui/popover";
import Chat from './Chat';
import LyricsChat from './LyricsChat';

function Layout({ 
  user, 
  credits, 
  creditsLoading, 
  onPurchaseSubscription, 
  onTopUpPurchase,
  onManageBilling, 
  isResetPasswordFlow, 
  isSubscriptionActive,
  cancelAtPeriodEnd,
  setShowOnboarding,
  subscriptionLoading,
  updateCredits
}) {
  const { signOut } = useAuth();
  const [showCarousel, setShowCarousel] = useState(false);
  const [showOutOfCreditsDialog, setShowOutOfCreditsDialog] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('midi');
  const [isCreditsButtonLoading, setIsCreditsButtonLoading] = useState(false);

  const handleLogoClick = () => {
    logEvent('Logo Clicked');
    window.location.href = '/';
  };

  const handleCreditsClick = async () => {
    setIsCreditsButtonLoading(true);
    logEvent('Credits Clicked', { isSubscriptionActive });
    try {
      if (isSubscriptionActive) {
        await onManageBilling();
      } else {
        await onPurchaseSubscription();
      }
    } catch (error) {
      console.error('Error handling credits click:', error);
    } finally {
      setIsCreditsButtonLoading(false);
    }
  };

  const handleTopUpClick = (amount) => {
    logEvent('Top Up Credits Clicked', { amount });
    onTopUpPurchase(amount);
  };

  const handleSubscribeClick = () => {
    logEvent('Subscribe Button Clicked');
    onPurchaseSubscription();
  };

  const handleManageBillingClick = () => {
    logEvent('Manage Billing Clicked');
    onManageBilling();
  };

  const handleLogoutClick = () => {
    logEvent('Logout Clicked');
    signOut();
  };

  const handleHelpClick = () => {
    logEvent('Onboarding Viewed from Help Popover');
    setShowOnboarding(true);
  };

  const handleCarouselClose = () => {
    logEvent('Help Carousel Closed');
    setShowCarousel(false);
  };

  const handlePurchase = () => {
    if (isSubscriptionActive) {
      logEvent('Manage Billing Clicked (From OutOfCreditsDialog)');
      onManageBilling();
    } else {
      logEvent('Subscribe Clicked (From OutOfCreditsDialog)');
      onPurchaseSubscription();
    }
    setShowOutOfCreditsDialog(false);
  };

  const handleContactClick = () => {
    logEvent('Contact Page Opened from Help Popover');
    navigate('/contact');
  };

  const handleFeedbackClick = () => {
    logEvent('Feedback Button Clicked');
    setShowFeedback(true);
  };

  const handleHelpPopoverOpen = () => {
    logEvent('Help Popover Opened');
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleCloseFeedback();
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    logEvent('Chat Tab Changed', { tab });
  };

  return (
    <div className="full-viewport-height flex flex-col relative">
      {user && !isResetPasswordFlow && (
        <header className="bg-transparent flex-shrink-0">
          <div className="w-full px-2 sm:px-4 py-3 flex flex-wrap justify-between items-center">
          <button 
              onClick={handleLogoClick}
              className="text-2xl font-bold hover:text-primary transition-colors flex items-center"
            >
              <Bot className="h-8 w-8 mr-2 text-primary" />
              MidiMate
            </button>
            
            <div className="flex items-center space-x-2 order-2 sm:order-3">
              <Button
                variant="ghost"
                size="sm"
                onClick={handleCreditsClick}
                disabled={isCreditsButtonLoading}
                className={`flex items-center text-xs sm:text-sm ${
                  !creditsLoading && credits === 0 ? 'text-red-500' : 
                  !creditsLoading && credits < 5 ? 'text-yellow-500' : ''
                }`}
              >
                {isCreditsButtonLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : (
                  <CreditCard className="h-4 w-4 mr-1 sm:mr-2" />
                )}
                <span>
                  {creditsLoading || isCreditsButtonLoading ? (
                    'Loading...'
                  ) : (
                    <span>{credits} Credits</span>
                  )}
                </span>
              </Button>
              
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="h-8 w-8 sm:h-10 sm:w-10"
                    onClick={() => logEvent('Menu Opened')}
                  >
                    <Menu className="h-5 w-5 sm:h-6 sm:w-6" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-56">
                  <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                      <p className="text-sm font-medium leading-none">Account</p>
                      <p className="text-xs leading-none text-muted-foreground">{user.email}</p>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {isSubscriptionActive && (
                    <DropdownMenuItem onClick={handleManageBillingClick}>
                      <Settings className="mr-2 h-5 w-5" />
                      <span>Manage Billing</span>
                    </DropdownMenuItem>
                  )}
                   <DropdownMenuItem onSelect={() => setShowFeedback(true)}>
                    <HelpCircle className="mr-2 h-5 w-5" />
                    <span>Feedback</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleLogoutClick}>
                    <LogOut className="mr-2 h-5 w-5" />
                    <span>Logout</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <div className="p-0 flex justify-center space-x-6">
                    <DropdownMenuItem asChild>
                      <a 
                        href="/terms-and-conditions" 
                        className="p-0 m-0 hover:bg-transparent focus:bg-transparent"
                        onClick={() => logEvent('Terms and Conditions Clicked')}
                      >
                        <FileText className="h-4 w-4 text-gray-600 hover:text-gray-800 transition-colors" />
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <a 
                        href="/privacy-policy" 
                        className="p-0 m-0 hover:bg-transparent focus:bg-transparent"
                        onClick={() => logEvent('Privacy Policy Clicked')}
                      >
                        <Shield className="h-4 w-4 text-gray-600 hover:text-gray-800 transition-colors" />
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <a 
                        href="/contact" 
                        className="p-0 m-0 hover:bg-transparent focus:bg-transparent"
                        onClick={() => logEvent('Contact Us Clicked')}
                      >
                        <Mail className="h-4 w-4 text-gray-600 hover:text-gray-800 transition-colors" />
                      </a>
                    </DropdownMenuItem>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            {/* Tabs for both mobile and desktop */}
            <div className="flex bg-gray-100 rounded-lg p-1 mt-2 w-full sm:w-auto sm:mt-0 order-3 sm:order-2">
              <Button
                variant={activeTab === 'midi' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => handleTabChange('midi')}
                className={`flex-1 sm:flex-initial flex items-center justify-center text-xs sm:text-sm ${
                  activeTab === 'midi' ? 'bg-black text-white hover:bg-black/90' : ''
                }`}
              >
                <Music className="h-4 w-4 sm:mr-2" />
                <span className="ml-1 sm:ml-0">MIDI</span>
              </Button>
              <Button
                variant={activeTab === 'lyrics' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => handleTabChange('lyrics')}
                className={`flex-1 sm:flex-initial flex items-center justify-center text-xs sm:text-sm relative ${
                  activeTab === 'lyrics' ? 'bg-black text-white hover:bg-black/90' : ''
                }`}
              >
                <Mic className="h-4 w-4 sm:mr-2" />
                <span className="ml-1 sm:ml-0">Lyrics</span>
                <span className="absolute -top-2 -right-2 bg-yellow-400 text-[10px] font-bold px-1 rounded-full">New!</span>
              </Button>
            </div>
          </div>
        </header>
      )}
      <main className="flex-grow overflow-hidden flex flex-col">
        <div className="h-full max-w-5xl mx-auto w-full flex flex-col">
          {activeTab === 'midi' ? (
            <Chat 
              credits={credits}
              updateCredits={updateCredits}
              onPurchaseSubscription={onPurchaseSubscription}
              onTopUpPurchase={onTopUpPurchase}
              isSubscriptionActive={isSubscriptionActive}
            />
          ) : (
            <LyricsChat 
              credits={credits}
              updateCredits={updateCredits}
              onPurchaseSubscription={onPurchaseSubscription}
              onTopUpPurchase={onTopUpPurchase}
              isSubscriptionActive={isSubscriptionActive}
            />
          )}
        </div>
      </main>

      {user && !isResetPasswordFlow && (
        <>
          {/* Help Popover */}
          <Popover onOpenChange={handleHelpPopoverOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="fixed bottom-4 right-4 rounded-full shadow-lg hidden lg:flex"
              >
                <HelpCircle className="h-6 w-6" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-56" align="end" side="top">
              <div className="grid gap-4">
                <Button
                  variant="ghost"
                  className="w-full justify-start"
                  onClick={handleHelpClick}
                >
                  <Info className="mr-2 h-4 w-4" />
                  View Onboarding
                </Button>
                <Button
                  variant="ghost"
                  className="w-full justify-start"
                  onClick={handleContactClick}
                >
                  <Mail className="mr-2 h-4 w-4" />
                  Contact Us
                </Button>
              </div>
            </PopoverContent>
          </Popover>

          {/* Feedback Button */}
          <Button
            variant="default"
            size="lg"
            className="fixed bottom-4 left-4 rounded-full shadow-lg flex items-center space-x-2 px-4 py-2 bg-primary text-primary-foreground hover:bg-primary/90 transition-all duration-200 ease-in-out hidden lg:flex"
            onClick={handleFeedbackClick}
          >
            <MessageSquarePlus className="h-5 w-5" />
            <span className="font-medium">Feedback</span>
          </Button>
        </>
      )}

      {showCarousel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <OnboardingCarousel onClose={handleCarouselClose} />
        </div>
      )}
      
      <OutOfCreditsDialog 
        isOpen={showOutOfCreditsDialog}
        onClose={() => {
          logEvent('Out of Credits Dialog Closed');
          setShowOutOfCreditsDialog(false);
        }}
        onPurchase={handlePurchase}
        onTopUp={handleTopUpClick}
        isSubscriptionActive={isSubscriptionActive}
      />

      {showFeedback && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 modal-overlay"
          onClick={handleOutsideClick}
        >
          <div className="bg-white p-4 rounded-lg w-full max-w-4xl h-[80vh] relative" onClick={(e) => e.stopPropagation()}>
            <Button 
              className="absolute top-2 right-2 z-10" 
              onClick={handleCloseFeedback}
              variant="ghost"
              size="sm"
            >
              <X className="h-4 w-4" />
            </Button>
            <div className="w-full h-full overflow-auto">
              <FeedbackWidget onClose={handleCloseFeedback} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Layout;
