import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from "./ui/button";
import { MessageSquare, Music, Layers, Sliders, Zap, Download, Bot, ArrowRight, Quote, X, LogIn, UserPlus } from 'lucide-react';
import MidiBackground from './MidiBackground';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import heroImage from '../assets/hero1.png';

const features = [
  {
    icon: <MessageSquare className="h-12 w-12 mb-4" />,
    title: "Chat-Based MIDI Creation",
    description: "Interact with MidiMate through a simple chat interface to create and refine your musical ideas."
  },
  {
    icon: <Music className="h-12 w-12 mb-4" />,
    title: "Create Chord Progressions",
    description: "Ask MidiMate to generate unique chord sequences with a simple text prompt."
  },
  {
    icon: <Layers className="h-12 w-12 mb-4" />,
    title: "Build Complex Compositions",
    description: "Request melodies, basslines, and harmonies to enhance your progression."
  },
  {
    icon: <Sliders className="h-12 w-12 mb-4" />,
    title: "Fine-tune Your Creation",
    description: "Tell MidiMate to adjust keys, modify chords, or tweak individual notes."
  },
  {
    icon: <Zap className="h-12 w-12 mb-4" />,
    title: "Instant MIDI Playback",
    description: "Hear your compositions instantly with built-in MIDI playback."
  },
  {
    icon: <Download className="h-12 w-12 mb-4" />,
    title: "Export and Use Anywhere",
    description: "Download your MIDI files and use them in any DAW with your favorite VSTs and instruments."
  }
];

function Header() {
  return (
    <header className="absolute top-0 right-0 p-4 z-50 flex space-x-2">
      <Link to="/login">
        <Button variant="outline" className="bg-white text-[#f05477] hover:bg-[#f05477] hover:text-white transition-all duration-300">
          <LogIn className="mr-2 h-4 w-4" /> Login
        </Button>
      </Link>
      <Link to="/signup">
        <Button className="bg-[#f05477] text-white hover:bg-[#d03357] transition-all duration-300">
          <UserPlus className="mr-2 h-4 w-4" /> Sign Up
        </Button>
      </Link>
    </header>
  );
}

function LandingPage() {
  const [showBanner, setShowBanner] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [user, navigate]);

  // If user is authenticated, don't render the landing page
  if (user) return null;

  return (
    <div className="min-h-screen relative overflow-hidden">
      <MidiBackground />
      <Header />
      <div className="relative z-10">
        <HeroSection />
        <FeaturesSection />
        <HowItWorksSection />
        <TestimonialSection />
        <CallToActionSection />
        <Footer />
      </div>
      {showBanner && (
        <NewFeatureBanner 
          onClose={() => setShowBanner(false)} 
          id="new-feature-banner"
        />
      )}
    </div>
  );
}

function NewFeatureBanner({ onClose, id }) {
  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="fixed bottom-4 right-4 bg-[#f05477] text-white p-4 rounded-full shadow-lg flex flex-col items-center justify-center z-50 w-24 h-24 cursor-pointer"
      id={id}
      onClick={() => window.location.href = '/signup'}
    >
      <span className="font-bold text-xs mb-1">New!</span>
      <span className="text-xs text-center">Lyrics Generation</span>
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }} 
        className="absolute -top-2 -right-2 bg-white text-[#f05477] rounded-full p-1 hover:bg-gray-200"
      >
        <X size={12} />
      </button>
    </motion.div>
  );
}

function HeroSection() {
  return (
    <motion.section 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="container mx-auto px-4 py-8 text-center text-black"
    >
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-col items-center mt-10 mb-12">
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="inline-flex items-center text-6xl font-bold text-primary mb-4"
          >
            <Bot className="h-20 w-20 mr-4" />
            MidiMate
          </motion.div>
        </div>
        <h1 className="text-5xl font-bold mb-4">
          Text to MIDI in{' '}
          <motion.span
            className="italic text-[#f05477]"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            Seconds
          </motion.span>
        </h1>
        <p className="text-xl mb-8">Supercharge Your Workflow: Compose, Iterate, and Create Faster with AI</p>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="mb-12"
        >
          <Link to="/signup">
            <Button size="lg" className="bg-[#f05477] hover:bg-[#d03357] text-white transition-all duration-300 hover:shadow-lg">
              Start Creating Now <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="relative w-full max-w-3xl mx-auto"
        >
          <motion.img
            src={heroImage}
            alt="MidiMate interface showing AI-powered MIDI creation"
            className="rounded-lg shadow-2xl w-full"
            animate={{ y: [0, -10, 0] }}
            transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
          />
          <div className="absolute inset-0 opacity-30 rounded-lg"></div>
        </motion.div>
      </div>
    </motion.section>
  );
}

function FeaturesSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section ref={ref} className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center mb-12 text-black">Unlock Your Musical Potential</h2>
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              delayChildren: 0.3,
              staggerChildren: 0.2
            }
          }
        }}
        initial="hidden"
        animate={controls}
      >
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </motion.div>
    </section>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <motion.div 
      variants={{
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
      }}
      whileHover={{ scale: 1.05 }}
      className="bg-black bg-opacity-50 p-6 rounded-lg shadow-md text-center text-white flex flex-col items-center"
    >
      <div className="mb-4">
        {React.cloneElement(icon, { className: "h-12 w-12" })}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-sm text-gray-300">{description}</p>
    </motion.div>
  );
}

function HowItWorksSection() {
  return (
    <section className="container mx-auto px-4 py-16 bg-black bg-opacity-50">
      <h2 className="text-3xl font-bold text-center mb-12 text-white">How It Works</h2>
      <div className="flex flex-col items-center space-y-8 lg:flex-row lg:justify-between lg:items-start lg:space-y-0 relative">
        <div className="absolute top-1/2 left-0 right-0 h-1 bg-[#f05477] hidden lg:block" style={{ transform: 'translateY(-50%)' }}></div>
        <Step number={1} title="Describe Your Idea" description="Chat with MidiMate about your desired MIDI" />
        <Step number={2} title="AI Composes" description="Watch MidiMate create music in real-time" />
        <Step number={3} title="Refine and Iterate" description="Collaborate with MidiMate to perfect your piece" />
        <Step number={4} title="Export to your DAW" description="Download the MIDI file and use it in your project" />
      </div>
    </section>
  );
}

function Step({ number, title, description }) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: number * 0.1 }}
      className="flex flex-col items-center mb-8 md:mb-0 text-center text-white max-w-xs relative z-10"
    >
      <div className="bg-[#f05477] text-white rounded-full w-16 h-16 flex items-center justify-center text-2xl font-bold mb-4">
        {number}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-sm text-gray-300">{description}</p>
    </motion.div>
  );
}

function TestimonialSection() {
  return (
    <section className="container mx-auto px-4 py-16 text-center">
      <h2 className="text-3xl font-bold mb-8 text-black">What Our Users Say</h2>
      <Testimonial 
        quote="MidiMate is now a core part of my songwriting process. I can effortlessly create chord progressions, try different keys and notes, and craft melodies—all through simple text commands. It's like having a personal composer at my fingertips!"
        author="Matt, Songwriter"
      />
    </section>
  );
}

function Testimonial({ quote, author }) {
  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto relative"
    >
      <Quote className="absolute top-4 left-4 text-gray-200 h-12 w-12 opacity-50" />
      <p className="text-lg italic mb-4 relative z-10">"{quote}"</p>
      <p className="font-semibold">- {author}</p>
    </motion.div>
  );
}

function CallToActionSection() {
  return (
    <motion.section 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="container mx-auto px-4 py-16 text-center text-black"
    >
      <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Music?</h2>
      <p className="text-xl mb-8">Join thousands of musicians already creating with MidiMate</p>
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link to="/signup">
          <Button size="lg" className="bg-[#f05477] hover:bg-[#d03357] text-white transition-all duration-300 hover:shadow-lg">
            Start For Free Now <ArrowRight className="ml-2 h-5 w-5" />
          </Button>
        </Link>
      </motion.div>
    </motion.section>
  );
}

function Footer() {
  return (
    <footer className="bg-black bg-opacity-75 text-white py-6">
      <div className="container mx-auto px-4">
        <div className="flex justify-center items-center space-x-6">
          <Link to="/privacy-policy" className="hover:text-[#f05477] transition-colors duration-300">Privacy Policy</Link>
          <Link to="/terms-and-conditions" className="hover:text-[#f05477] transition-colors duration-300">Terms and Conditions</Link>
          <Link to="/contact" className="hover:text-[#f05477] transition-colors duration-300">Contact Us</Link>
        </div>
      </div>
    </footer>
  );
}

export default LandingPage;