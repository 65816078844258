import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { ScrollArea } from "./ui/scroll-area"
import { Send, RefreshCw, Loader2, User, Bot, ChevronLeft, ChevronRight, Copy, Check } from 'lucide-react';
import LoadingSpinner from './LoadingSpinner';
import OutOfCreditsDialog from './OutOfCreditsDialog';
import EmptyState from './EmptyState';
import { callFunction } from '../lib/functionCaller';
import { logEvent } from '../lib/amplitude';
import ClearHistoryDialog from './ClearHistoryDialog';

const MAX_RETRIES = 3;

const lyricsLoadingMessages = [
  "Brainstorming rhymes...",
  "Crafting verses...",
  "Composing choruses...",
  "Penning metaphors...",
  "Weaving narratives...",
  "Polishing lyrics...",
  "Finding the perfect words...",
  "Exploring themes...",
  "Harmonizing ideas...",
  "Structuring the song...",
];

const fetchChatHistory = async (userId) => {
  try {
    const { data: chatHistory, error: chatError } = await supabase
      .from('chat_history')
      .select('*')
      .eq('user_id', userId)
      .eq('chat_type', 'lyrics')
      .order('created_at', { ascending: true });

    if (chatError) throw chatError;

    return chatHistory.map(message => ({
      ...message,
      role: message.role,
      content: typeof message.message === 'string' ? message.message : JSON.stringify(message.message),
      suggestions: message.suggestions ? JSON.parse(message.suggestions) : [],  // Add this line
    }));
  } catch (error) {
    console.error('Error fetching chat history');
    return [];
  }
};

function LyricsChat({ credits, updateCredits, onPurchaseSubscription, onTopUpPurchase, isSubscriptionActive }) {
  const { user, signOut } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const scrollAreaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isClearing, setIsClearing] = useState(false);
  const [isOutOfCreditsDialogOpen, setIsOutOfCreditsDialogOpen] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isClearConfirmOpen, setIsClearConfirmOpen] = useState(false);
  const [isInputFlashing, setIsInputFlashing] = useState(false);
  const [loadingIntervalId, setLoadingIntervalId] = useState(null);
  const [isWaitingForNewSuggestions, setIsWaitingForNewSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const initialSuggestions = [
    "Write a love song",
    "Create lyrics for a dance track",
    "Compose a ballad about heartbreak",
    "Write a rap verse about success",
    "Generate lyrics for a rock anthem",
    "Create a country song about home",
  ];

  const [currentSuggestions, setCurrentSuggestions] = useState(initialSuggestions);

  const hasHistoryToClear = messages.length > 0;

  const suggestionsRef = useRef(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScrollability = () => {
    if (suggestionsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = suggestionsRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [suggestions]);

  const handleWheel = (e) => {
    if (e.deltaY !== 0 && suggestionsRef.current) {
      e.preventDefault();
      suggestionsRef.current.scrollLeft += e.deltaY;
      checkScrollability();
    }
  };

  const scrollSuggestions = (direction) => {
    if (suggestionsRef.current) {
      const scrollAmount = 200;
      suggestionsRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(checkScrollability, 300); // Check after scroll animation
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (user) {
        setIsLoadingHistory(true);
        try {
          const history = await fetchChatHistory(user.id);
          setMessages(history);
          if (history.length > 0) {
            const lastMessage = history[history.length - 1];
            setSuggestions(lastMessage.suggestions || []);  // Update this line
          } else {
            setSuggestions([]);  // Update this line
          }

          logEvent('Lyrics Chat Session Started', { 
            hasExistingHistory: history.length > 0
          });
        } catch (error) {
          console.error('Error fetching chat history');
          setSuggestions([]);  // Update this line
        } finally {
          setIsLoadingHistory(false);
        }
      } else {
        setSuggestions([]);  // Update this line
        setIsLoadingHistory(false);
      }
    };

    fetchSession();
  }, [user]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const cycleLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * lyricsLoadingMessages.length);
    setLoadingMessageIndex(randomIndex);
  };

  const generateLyrics = async (inputText, retry = false, retryCount = 0) => {
    if (!retry) {
      setIsLoading(true);
      cycleLoadingMessage();
      const newIntervalId = setInterval(cycleLoadingMessage, 2000);
      setLoadingIntervalId(newIntervalId);
      const userMessage = { role: 'user', content: inputText };
      setMessages(prev => [...prev, userMessage]);
    }

    try {
      const response = await callFunction('generate-lyrics', {
        messages: [...messages, { role: 'user', content: inputText }],
        userId: user.id
      });

      if (response.error) {
        throw new Error(response.error);
      }

      const newMessage = { 
        role: 'assistant', 
        content: response.lyrics,
        description: response.description, // Add this line
      };
      setMessages(prev => [...prev, newMessage]);
      setSuggestions(response.suggestions || []);
      setIsWaitingForNewSuggestions(false);
      setIsLoading(false);

      clearInterval(loadingIntervalId);
      setLoadingIntervalId(null);

      logEvent('Lyrics Generated', { inputLength: inputText.length, outputLength: response.lyrics.length });

      return { newCreditBalance: response.newCreditBalance };
    } catch (error) {
      console.error('Error generating lyrics');
      if (retryCount < MAX_RETRIES) {
        return generateLyrics(inputText, true, retryCount + 1);
      } else {
        clearInterval(loadingIntervalId);
        setLoadingIntervalId(null);
        setIsLoading(false);

        const errorMessage = { 
          role: 'assistant', 
          content: "I'm sorry, but I encountered an issue while generating the lyrics. Please try again with a different request or rephrase your input.",
          isError: true
        };
        logEvent('Lyrics Generation Error', { error: error.message });
        setMessages(prev => [...prev, errorMessage]);
      }
    }
  };

  const handleSubmit = async (e, suggestedInput = null) => {
    if (e) e.preventDefault();
    const inputText = suggestedInput || input.trim();
    
    if (!inputText || !user) return;
    
    // Add this check for credits
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive, fromInput: true });
      return;
    }
    
    logEvent('Lyrics Chat Message Sent', { messageLength: inputText.length, message: inputText });

    try {
      const { newCreditBalance } = await generateLyrics(inputText);
      updateCredits(newCreditBalance);
      setInput(''); // Clear the input after sending
    } catch (error) {
      console.error('Error sending message');
    }
  };

  const handleClearHistory = async () => {
    if (!user) return;

    setIsClearing(true);
    setIsClearConfirmOpen(false);
    logEvent('Clear Lyrics History Initiated');

    try {
      const { data: deletedChatHistory, error: chatError } = await supabase
        .from('chat_history')
        .delete()
        .eq('user_id', user.id)
        .eq('chat_type', 'lyrics');

      if (chatError) {
        console.error('Error clearing lyrics chat history');
        throw chatError;
      }

      setMessages([]);
      setCurrentSuggestions(initialSuggestions);
      logEvent('Lyrics History Cleared', { messageCount: messages.length });
    } catch (error) {
      console.error('Error clearing lyrics chat history:', error);
      logEvent('Clear Lyrics History Error', { error: error.message });
    } finally {
      setIsClearing(false);
    }
  };

  const handleOutOfCreditsDialogClose = () => {
    setIsOutOfCreditsDialogOpen(false);
    if (messages.length === 0) {
      setCurrentSuggestions(initialSuggestions);
    }
  };

  const handlePurchaseCredits = () => {
    setIsOutOfCreditsDialogOpen(false);
    onPurchaseSubscription();
  };

  const handleTopUpPurchase = (amount) => {
    setIsOutOfCreditsDialogOpen(false);
    onTopUpPurchase(amount);
  };

  useEffect(() => {
    return () => {
      if (loadingIntervalId) {
        clearInterval(loadingIntervalId);
      }
    };
  }, [loadingIntervalId]);

  const handleSuggestionClick = async (suggestion) => {
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive, fromSuggestion: true });
      return;
    }

    if (messages.length === 0) {
      setIsWaitingForNewSuggestions(true);
      setCurrentSuggestions([]); // Clear current suggestions
      await handleSubmit(null, suggestion);
      setIsWaitingForNewSuggestions(false);
    } else {
      setInput(suggestion);
      setIsInputFlashing(true);
      setTimeout(() => setIsInputFlashing(false), 300);
    }

    if (messages.length > 0) {
      const inputElement = document.querySelector('input[type="text"]');
      if (inputElement) {
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    logEvent('Lyrics Suggestion Clicked', { 
      suggestion,
      isInitialSuggestion: messages.length === 0
    });
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
    });
  };

  const renderMessage = (message, index) => {
    if (message.role === 'user') {
      return <p className="break-words whitespace-pre-wrap">{message.content}</p>;
    } else {
      return (
        <>
          {message.description && (
            <div className="mb-2 text-sm font-medium text-[#f05477] bg-[#f05477]/10 p-2 rounded">
              {message.description}
            </div>
          )}
          <div className="relative">
            <pre className="whitespace-pre-wrap font-sans text-base break-words pr-8">{message.content}</pre>
            <button
              onClick={() => copyToClipboard(message.content, index)}
              className="absolute top-0 right-0 p-1 text-gray-500 hover:text-gray-700"
              aria-label="Copy to clipboard"
            >
              {copiedIndex === index ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </button>
          </div>
        </>
      );
    }
  };

  const displayedSuggestions = windowWidth < 640 ? currentSuggestions.slice(0, 3) : currentSuggestions;

  const MAX_CHARS = 450; // Set your desired character limit here

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= MAX_CHARS) {
      setInput(inputText);
    }
  };

  return (
    <div className="h-full flex flex-col bg-transparent">
      <ScrollArea className="flex-grow overflow-y-auto" ref={scrollAreaRef}>
        <div className="max-w-1xl mx-auto px-4 py-6 space-y-6 h-full">
          {isLoadingHistory || isClearing ? (
            <div className="flex justify-center items-center h-full">
              <LoadingSpinner text={isClearing ? "Clearing chat history..." : "Loading chat history..."} />
            </div>
          ) : messages.length === 0 ? (
            <EmptyState 
              suggestions={initialSuggestions} 
              onSuggestionClick={handleSuggestionClick}
              context="lyrics"
            />
          ) : (
            messages.map((message, index) => (
              <React.Fragment key={index}>
                <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                  <div className={`flex items-start space-x-3 ${message.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'} max-w-[85%] sm:max-w-[75%] md:max-w-[600px]`}>
                    <div className={`flex-shrink-0 rounded-full p-2 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-muted'}`}>
                      {message.role === 'user' ? <User className="h-5 w-5" /> : <Bot className="h-5 w-5" />}
                    </div>
                    <div className={`rounded-lg p-4 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-gray-100'} overflow-hidden w-full`}>
                      {renderMessage(message, index)}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          )}
          {isLoading && (
            <div className="flex justify-start">
              <div className="flex items-start space-x-3 max-w-[85%]">
                <div className="flex-shrink-0 rounded-full p-2 bg-muted">
                  <Bot className="h-5 w-5" />
                </div>
                <div className="rounded-lg p-4 bg-gray-100 overflow-hidden">
                  <LoadingSpinner text={lyricsLoadingMessages[loadingMessageIndex]} />
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </ScrollArea>
      
      <div className="bg-transparent">
        <div className="max-w-1xl mx-auto px-4 py-4">
          {suggestions.length > 0 && !isWaitingForNewSuggestions && !isClearing && (
            <div className="relative">
              <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-white to-transparent z-10"></div>
              <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-white to-transparent z-10"></div>
              <div 
                ref={suggestionsRef}
                className="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent px-12" 
                style={{ WebkitOverflowScrolling: 'touch' }}
                onWheel={handleWheel}
                onScroll={checkScrollability}
              >
                <div className="flex flex-nowrap gap-2 pb-2" style={{ minWidth: 'min-content' }}>
                  {suggestions.map((suggestion, index) => (
                    <Button
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      variant="outline"
                      size="sm"
                      className="text-xs sm:text-sm py-1 px-3 rounded-full text-[#f05477] bg-white border-2 border-[#f05477] hover:bg-[#f05477] hover:text-white transition-colors duration-300 whitespace-nowrap flex-shrink-0 shadow-sm"
                    >
                      {suggestion}
                    </Button>
                  ))}
                </div>
              </div>
              {canScrollLeft && (
                <button
                  onClick={() => scrollSuggestions('left')}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
                  aria-label="Scroll left"
                >
                  <ChevronLeft className="h-5 w-5 text-white" />
                </button>
              )}
              {canScrollRight && (
                <button
                  onClick={() => scrollSuggestions('right')}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
                  aria-label="Scroll right"
                >
                  <ChevronRight className="h-5 w-5 text-white" />
                </button>
              )}
            </div>
          )}
          <div className="flex flex-col space-y-2">
            <form onSubmit={(e) => handleSubmit(e)} className="flex space-x-2">
              <div className="relative flex-grow">
                <Input
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder={windowWidth < 640 ? "Type your lyrics request..." : "Describe the lyrics you want to generate..."}
                  disabled={isLoading}
                  className={`w-full pr-16 ${isInputFlashing ? 'input-flash' : ''} text-base sm:text-sm`}
                  onFocus={() => logEvent('Lyrics Chat Input Focused')}
                  maxLength={MAX_CHARS}
                />
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                  {input.length}/{MAX_CHARS}
                </span>
              </div>
              <Button 
                type="submit" 
                disabled={isLoading || !input.trim()} 
                className="h-10 px-4 py-2 text-sm font-medium text-white bg-[#f05477] hover:bg-[#d03357] rounded-md transition-colors duration-300 flex items-center justify-center space-x-2 shadow-sm"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <Send className="h-5 w-5" />
                    <span className="hidden sm:inline">Send</span>
                  </>
                )}
              </Button>
              <Button 
                onClick={() => setIsClearConfirmOpen(true)}
                variant="outline"
                disabled={isClearing || !hasHistoryToClear}
                className="h-10 px-4 py-2 text-sm font-medium text-black bg-white border-2 border-black rounded-md hover:bg-black hover:text-white transition-colors duration-300 flex items-center justify-center space-x-2 shadow-sm"
              >
                {isClearing ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <RefreshCw className="h-5 w-5" />
                    <span className="hidden sm:inline">Clear</span>
                  </>
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
      
      <ClearHistoryDialog 
        isOpen={isClearConfirmOpen}
        onClose={() => setIsClearConfirmOpen(false)}
        onClear={handleClearHistory}
        isClearing={isClearing}
      />
      
      <OutOfCreditsDialog 
        isOpen={isOutOfCreditsDialogOpen}
        onClose={handleOutOfCreditsDialogClose}
        onPurchase={handlePurchaseCredits}
        onTopUp={handleTopUpPurchase}
        isSubscriptionActive={isSubscriptionActive}
      />
    </div>
  );
}

export default LyricsChat;