import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Gift, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function PromotionalBanner() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    // until 10/10/2024
    const endDate = new Date('2024-10-10');
    const difference = endDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60) + 3) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const handleClick = (e) => {
    e.stopPropagation(); 
    navigate('/signup');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onClick={handleClick}
      className="bg-[#f05477] hover:bg-[#d03357] text-white py-2 px-4 text-center shadow-md transition-colors cursor-pointer"
      style={{
        position: 'relative',
        zIndex: 1000,
      }}
    >
      <div className="flex items-center justify-center">
        <Gift className="h-5 w-5 mr-2" />
        <p className="text-sm font-medium mr-2">
          Limited Time Offer: Sign up today and get 5 free credits! No credit card required.
        </p>
        <Clock className="h-5 w-5 mr-1" />
      </div>
    </motion.div>
  );
}

export default PromotionalBanner;