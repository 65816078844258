import React from 'react';
import { Bot } from 'lucide-react';
import { Button } from "./ui/button";
import { logEvent } from '../lib/amplitude';

function EmptyState({ suggestions, onSuggestionClick, context = 'midi' }) {
  const handleSuggestionClick = (suggestion) => {
    logEvent('Empty State Suggestion Clicked', { suggestion, context });
    onSuggestionClick(suggestion);
  };

  React.useEffect(() => {
    logEvent('Empty State Viewed', { context });
  }, [context]);

  const getMessage = () => {
    switch (context) {
      case 'lyrics':
        return "Start by describing the lyrics you want to create or try one of these examples:";
      case 'midi':
      default:
        return "Start by describing the midi you want to create or try one of these examples:";
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full text-center px-4">
      <Bot className="h-16 w-16 text-primary mb-6" />
      <h2 className="text-2xl font-bold mb-2">Welcome to MidiMate!</h2>
      <p className="text-gray-600 mb-8">{getMessage()}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-2xl w-full">
        {suggestions.map((suggestion, index) => (
          <Button
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            variant="outline"
            size="lg"
            className="text-base py-3 px-6 h-auto border-2 border-primary hover:bg-primary hover:text-white transition-colors duration-300 shadow-md"
          >
            {suggestion}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default EmptyState;
