import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import MidiPlayer from './MidiPlayer';
import { supabase } from '../lib/supabase';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { ScrollArea } from "./ui/scroll-area"
import { Send, RefreshCw, Loader2, User, Bot, Undo, ChevronLeft, ChevronRight } from 'lucide-react'; // Import User, Bot, Undo, ChevronLeft, and ChevronRight icons
import LoadingSpinner from './LoadingSpinner';
import OutOfCreditsDialog from './OutOfCreditsDialog';
import EmptyState from './EmptyState';
import { callFunction } from '../lib/functionCaller';
import { logEvent } from '../lib/amplitude';
import ClearHistoryDialog from './ClearHistoryDialog';
import UndoDialog from './UndoDialog';


const MAX_RETRIES = 3;

const musicalLoadingMessages = [
  "Tuning the guitar...",
  "Warming up the orchestra...",
  "Polishing the piano keys...",
  "Rosining the violin bow...",
  "Clearing the conductor's throat...",
  "Adjusting the drum skins...",
  "Oiling the trombone slides...",
  "Dusting off the sheet music...",
  "Tightening the harp strings...",
  "Warming up the vocal cords...",
  "Calibrating the metronome...",
  "Arranging the choir...",
  "Preparing the stage...",
  "Setting up the microphones...",
  "Shuffling the playlist...",
  "Charging up the synthesizer...",
  "Polishing the brass section...",
  "Aligning the woodwinds...",
  "Synchronizing the rhythm section..."
];

const fetchChatHistory = async (userId) => {
  try {
    const { data: chatHistory, error: chatError } = await supabase
      .from('chat_history')
      .select(`
        *,
        midi_files (
          file_path,
          description,
          lilypond_code,
          suggestions
        )
      `)
      .eq('user_id', userId)
      .eq('chat_type', 'midi')  // Add this line
      .order('created_at', { ascending: true });

    if (chatError) throw chatError;

    const combinedHistory = chatHistory.map(message => ({
      ...message,
      role: message.role,
      content: typeof message.message === 'string' ? message.message : JSON.stringify(message.message),
      filePath: message.midi_files?.file_path || null,
      description: message.midi_files?.description || null,
      lilypond_code: message.midi_files?.lilypond_code || null,
      suggestions: message.midi_files?.suggestions ? JSON.parse(message.midi_files.suggestions) : []
    }));

    return combinedHistory;
  } catch (error) {
    console.error('Error fetching chat history');
    return [];
  }
};

function Chat({ credits, updateCredits, onPurchaseSubscription, onTopUpPurchase, isSubscriptionActive }) {
  const { user, signOut } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [currentLilyPondCode, setCurrentLilyPondCode] = useState('');
  const scrollAreaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isClearing, setIsClearing] = useState(false);
  const [isOutOfCreditsDialogOpen, setIsOutOfCreditsDialogOpen] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [isWaitingForNewSuggestions, setIsWaitingForNewSuggestions] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isClearConfirmOpen, setIsClearConfirmOpen] = useState(false);
  const [isInputFlashing, setIsInputFlashing] = useState(false);
  const [loadingIntervalId, setLoadingIntervalId] = useState(null);
  const [previousLilyPondCode, setPreviousLilyPondCode] = useState('');
  const MAX_CHARS = 150; // Set the maximum character limit
  const [isUndoDialogOpen, setIsUndoDialogOpen] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const initialSuggestions = [
    "Create a simple chord progression",
    "Play me I–V–VI–IV chords in C major",
    "What do VI–IV–I–V chords with seventh notes look like?",
    "Give me a melody with a complementing countermelody",
    "Generate a catchy melody in F major",
    "Play a somber chord progression",
  ];

  // Add this new function to check if there's any history to clear
  const hasHistoryToClear = messages.length > 0;

  // Add this helper function to count assistant messages
  const countAssistantMessages = (messages) => {
    return messages.filter(message => message.role === 'assistant').length;
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (user) {
        setIsLoadingHistory(true);
        try {
          const history = await fetchChatHistory(user.id);
          setMessages(history);
          if (history.length > 0) {
            const lastMessage = history[history.length - 1];
            setCurrentLilyPondCode(lastMessage.lilypond_code || '');
            setPreviousLilyPondCode(lastMessage.lilypond_code || '');
            
            // Only include "Undo" if there's more than one assistant message
            const suggestions = lastMessage.suggestions || initialSuggestions;
            if (countAssistantMessages(history) > 1) {
              setCurrentSuggestions(["Undo", ...suggestions.slice(0, 5)]);
            } else {
              setCurrentSuggestions(suggestions.slice(0, 5));
            }
          } else {
            setCurrentSuggestions(initialSuggestions);
          }

          logEvent('Chat Session Started', { 
            hasExistingHistory: history.length > 0
          });
        } catch (error) {
          console.error('Error fetching chat history');
          setCurrentSuggestions(initialSuggestions);
        } finally {
          setIsLoadingHistory(false);
        }
      } else {
        setCurrentSuggestions(initialSuggestions);
        setIsLoadingHistory(false);
      }
    };

    fetchSession();

    // ... rest of the useEffect
  }, [user]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayedSuggestions = windowWidth < 640 ? currentSuggestions.slice(0, 3) : currentSuggestions;

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const cycleLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * musicalLoadingMessages.length);
    setLoadingMessageIndex(randomIndex);
  };

  const generateMidi = async (inputText, retry = false, retryCount = 0) => {
    if (!retry) {
      setIsLoading(true);
      cycleLoadingMessage();
      const newIntervalId = setInterval(cycleLoadingMessage, 2000);
      setLoadingIntervalId(newIntervalId);
      const userMessage = { role: 'user', content: inputText };
      setMessages(prev => [...prev, userMessage]);
      setPreviousLilyPondCode(currentLilyPondCode); // Store the current code before generating new MIDI
    }

    try {
      if (!user) throw new Error('No active session');

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      let data;
   
      const invokeData = await callFunction('generate-midi', {
        messages: [...messages, { role: 'user', content: inputText }],
        currentLilyPondCode,
        userId: user.id
      });
      
      data = invokeData;

      logEvent('MIDI Generated', { 
        messageCount: messages.length,
        lilyPondCodeLength: data.lilypondCode.length,
      });

      const botMessage = { 
        role: 'assistant', 
        content: data.description,
        midiData: new Uint8Array(data.midiData),
        description: data.description,
        filePath: data.filePath
      };

      setMessages(prev => [...prev, botMessage]);
      setCurrentLilyPondCode(data.lilypondCode);
      setCurrentSuggestions(["Undo", ...(data.suggestions || initialSuggestions)]);
      setIsWaitingForNewSuggestions(false);
      setIsLoading(false);

      // On success, clear the interval and reset loading state
      clearInterval(loadingIntervalId);
      setLoadingIntervalId(null);
      setIsLoading(false);

      return { newCreditBalance: data.newCreditBalance };
    } catch (error) {
      console.error('Error generating MIDI');
      if (retryCount < MAX_RETRIES) {
        return generateMidi(inputText, true, retryCount + 1);
      } else {
        // On final failure, clear the interval and reset loading state
        clearInterval(loadingIntervalId);
        setLoadingIntervalId(null);
        setIsLoading(false);

        const errorMessage = { 
          role: 'assistant', 
          content: "I'm sorry, but I encountered an issue while generating the MIDI. Please try again with a different request or rephrase your input.",
          isError: true
        };
        logEvent('MIDI Generation Error', { error: error.message });
        setMessages(prev => [...prev, errorMessage]);
        setIsWaitingForNewSuggestions(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= MAX_CHARS) {
      setInput(inputText);
    }
  };

  const handleSubmit = async (e, suggestedInput = null) => {
    if (e) e.preventDefault();
    const inputText = suggestedInput || input.trim(); // Trim the input
    
    if (!inputText) return; // Don't proceed if input is empty
    
    logEvent('Chat Message Sent', { messageLength: inputText.length, message: inputText });

    if (!user) {
      // Handle not logged in case
      return;
    }
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive });
      return;
    }

    setIsLoading(true);
    try {
      // Call the MIDI generation function
      const result = await generateMidi(inputText);
      
      // If successful, update the local credit count
      updateCredits(result.newCreditBalance);
      
      setInput('');
    } catch (error) {

      console.error('Error sending message');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearHistory = async () => {
    if (!user) return;

    setIsClearing(true);
    setIsClearConfirmOpen(false);
    try {
      // Clear chat history from Supabase
      const { data: deletedChatHistory, error: chatError } = await supabase
        .from('chat_history')
        .delete()
        .eq('user_id', user.id)
        .eq('chat_type', 'midi');  // Add this line

      if (chatError) {
        console.error('Error clearing chat history');
        throw chatError;
      }

      // Clear MIDI files from Supabase storage
      const { data: midiFiles, error: listError } = await supabase
        .storage
        .from('midi-files')
        .list(user.id);

      if (listError) {
        console.error('Error listing MIDI files');
        throw listError;
      }

      if (midiFiles && midiFiles.length > 0) {
        const filesToRemove = midiFiles.map(file => `${user.id}/${file.name}`);
        const { data: deletedFiles, error: deleteError } = await supabase
          .storage
          .from('midi-files')
          .remove(filesToRemove);

        if (deleteError) {
          console.error('Error deleting MIDI files');
          throw deleteError;
        }
      }

      // Clear MIDI file metadata from Supabase
      const { data: deletedMidiMetadata, error: midiMetadataError } = await supabase
        .from('midi_files')
        .delete()
        .eq('user_id', user.id);

      if (midiMetadataError) {
        console.error('Error clearing MIDI metadata');
        throw midiMetadataError;
      }

      // Clear frontend state
      setMessages([]);
      setCurrentLilyPondCode('');
      setCurrentSuggestions(initialSuggestions);

      logEvent('MIDI Chat History Cleared', { 
        messageCount: messages.length,
        midiFileCount: midiFiles.length
      });

    } catch (error) {
      console.error('Error clearing chat history and MIDI files');
    } finally {
      setIsClearing(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive, fromSuggestion: true });
      return;
    }

    if (suggestion === "Undo") {
      setIsUndoDialogOpen(true);
    } else {
      if (messages.length === 0) {
        setIsWaitingForNewSuggestions(true);
        setCurrentSuggestions([]); // Clear current suggestions
        await handleSubmit(null, suggestion);
        setIsWaitingForNewSuggestions(false);
      } else {
        setInput(suggestion);
        setIsInputFlashing(true);
        setTimeout(() => setIsInputFlashing(false), 300);
      }
    }

    if (messages.length > 0) {
      const inputElement = document.querySelector('input[type="text"]');
      if (inputElement) {
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    logEvent('Suggestion Clicked', { 
      suggestion,
      isInitialSuggestion: messages.length === 0,
      isUndo: suggestion === "Undo"
    });
  };

  const handlePurchaseCredits = () => {
    setIsOutOfCreditsDialogOpen(false);
    onPurchaseSubscription();
  };

  const handleTopUpPurchase = (amount) => {
    setIsOutOfCreditsDialogOpen(false);
    onTopUpPurchase(amount);
  };

  const handleOutOfCreditsDialogClose = () => {
    setIsOutOfCreditsDialogOpen(false);
    if (messages.length === 0) {
      setCurrentSuggestions(initialSuggestions);
    }
  };

  // Don't forget to clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (loadingIntervalId) {
        clearInterval(loadingIntervalId);
      }
    };
  }, [loadingIntervalId]);

  const handleUndo = async () => {
    const undoPrompt = "Undo the last change and revert to the previous state.";
    setInput(undoPrompt);
    setCurrentLilyPondCode(previousLilyPondCode);
    
    // Log the undo event
    logEvent('Undo Action Performed', {
      previousCodeLength: previousLilyPondCode.length,
      messageCount: messages.length
    });

    await handleSubmit(null, undoPrompt);
    setIsUndoDialogOpen(false); // Close the dialog after undoing
  };

  const suggestionsRef = useRef(null);

  const checkScrollability = () => {
    if (suggestionsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = suggestionsRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [currentSuggestions]);

  const handleWheel = (e) => {
    if (e.deltaY !== 0 && suggestionsRef.current) {
      e.preventDefault();
      suggestionsRef.current.scrollLeft += e.deltaY;
      checkScrollability();
    }
  };

  const scrollSuggestions = (direction) => {
    if (suggestionsRef.current) {
      const scrollAmount = 200;
      suggestionsRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(checkScrollability, 300); // Check after scroll animation
    }
  };

  return (
    <div className="h-full flex flex-col bg-transparent">
      <ScrollArea className="flex-grow overflow-y-auto" ref={scrollAreaRef}>
        <div className="max-w-1xl mx-auto px-4 py-6 space-y-6 h-full">
          {isLoadingHistory || isClearing ? (
            <div className="flex justify-center items-center h-full">
              <LoadingSpinner text={isClearing ? "Clearing chat history..." : "Loading chat history..."} />
            </div>
          ) : messages.length === 0 ? (
            <EmptyState 
              suggestions={currentSuggestions} 
              onSuggestionClick={handleSuggestionClick}
              context="midi"
            />
          ) : (
            messages.map((message, index) => (
              <React.Fragment key={index}>
                <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                  <div className={`flex items-start space-x-3 ${message.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'} max-w-[85%] sm:max-w-[75%] md:max-w-[600px]`}>
                    <div className={`flex-shrink-0 rounded-full p-2 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-muted'}`}>
                      {message.role === 'user' ? <User className="h-5 w-5" /> : <Bot className="h-5 w-5" />}
                    </div>
                    <div className={`rounded-lg p-4 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-gray-100'} overflow-hidden w-full`}>
                      <p className="break-words">{message.content || "No content"}</p>
                      {message.role === 'assistant' && message.filePath && (
                        <div className="mt-4 w-full max-w-[300px] sm:max-w-full mx-auto">
                          <MidiPlayer 
                            filePath={message.filePath} 
                            onPlay={() => logEvent('MIDI Playback Started', { filePath: message.filePath })}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          )}
          {isLoading && (
            <div className="flex justify-start">
              <div className="flex items-start space-x-3 max-w-[85%]">
                <div className="flex-shrink-0 rounded-full p-2 bg-muted">
                  <Bot className="h-5 w-5" />
                </div>
                <div className="rounded-lg p-4 bg-gray-100 overflow-hidden">
                  <LoadingSpinner text={musicalLoadingMessages[loadingMessageIndex]} />
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </ScrollArea>
      
    

      <div className="bg-transparent">
        <div className="max-w-1xl mx-auto px-4 py-4">
            {/* Only show suggestions above chatbox when there are messages and not clearing history */}
      {messages.length > 0 && currentSuggestions.length > 0 && !isWaitingForNewSuggestions && !isClearing && (
        <div className="relative">
          <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-white to-transparent z-10"></div>
          <div 
            ref={suggestionsRef}
            className="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent px-12" 
            style={{ WebkitOverflowScrolling: 'touch' }}
            onWheel={handleWheel}
            onScroll={checkScrollability}
          >
            <div className="flex flex-nowrap gap-2 pb-2" style={{ minWidth: 'min-content' }}>
              {displayedSuggestions.map((suggestion, sugIndex) => (
                suggestion === "Undo" ? (
                  <Button
                    key={sugIndex}
                    onClick={() => handleSuggestionClick(suggestion)}
                    variant="outline"
                    size="sm"
                    className="text-xs sm:text-sm py-1 px-3 rounded-full text-black bg-white border-2 border-black hover:bg-black hover:text-white transition-colors duration-300 whitespace-nowrap flex-shrink-0 shadow-sm flex items-center space-x-1"
                  >
                    <Undo className="h-3 w-3" />
                    <span>{suggestion}</span>
                  </Button>
                ) : (
                  <Button
                    key={sugIndex}
                    onClick={() => handleSuggestionClick(suggestion)}
                    variant="outline"
                    size="sm"
                    className="text-xs sm:text-sm py-1 px-3 rounded-full text-[#f05477] bg-white border-2 border-[#f05477] hover:bg-[#f05477] hover:text-white transition-colors duration-300 whitespace-nowrap flex-shrink-0 shadow-sm"
                  >
                    {suggestion}
                  </Button>
                )
              ))}
            </div>
          </div>
          {canScrollLeft && (
            <button
              onClick={() => scrollSuggestions('left')}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
              aria-label="Scroll left"
            >
              <ChevronLeft className="h-5 w-5 text-white" />
            </button>
          )}
          {canScrollRight && (
            <button
              onClick={() => scrollSuggestions('right')}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
              aria-label="Scroll right"
            >
              <ChevronRight className="h-5 w-5 text-white" />
            </button>
          )}
        </div>
      )}
          <div className="flex space-x-2">
            <form onSubmit={(e) => handleSubmit(e)} className="flex space-x-2 flex-grow">
              <div className="relative flex-grow">
                <Input
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder={windowWidth < 640 ? "Type your desired chords, melody, etc..." : "Describe your desired chord progression, melody, modifications, etc."}
                  disabled={isLoading}
                  className={`w-full pr-16 ${isInputFlashing ? 'input-flash' : ''} text-base sm:text-sm`}
                  onFocus={() => logEvent('Chat Input Focused')}
                  maxLength={MAX_CHARS}
                />
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                  {input.length}/{MAX_CHARS}
                </span>
              </div>
              <Button 
                type="submit" 
                disabled={isLoading || !input.trim()} 
                className="w-10 h-10 sm:w-auto sm:h-auto px-2 sm:px-4 py-2 text-sm font-medium text-white bg-[#f05477] hover:bg-[#d03357] rounded-md transition-colors duration-300 flex items-center justify-center sm:justify-start space-x-0 sm:space-x-2 shadow-sm"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <Send className="h-5 w-5" />
                    <span className="hidden sm:inline">Send</span>
                  </>
                )}
              </Button>
            </form>
            <Button 
              onClick={() => setIsClearConfirmOpen(true)}
              variant="outline"
              disabled={isClearing || !hasHistoryToClear}
              className="w-10 h-10 sm:w-auto sm:h-auto px-2 sm:px-4 py-2 text-sm font-medium text-black bg-white border-2 border-black rounded-md hover:bg-black hover:text-white transition-colors duration-300 flex items-center justify-center sm:justify-start space-x-0 sm:space-x-2 shadow-sm"
            >
              {isClearing ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <>
                  <RefreshCw className="h-5 w-5" />
                  <span className="hidden sm:inline">Clear</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>

      <ClearHistoryDialog 
        isOpen={isClearConfirmOpen}
        onClose={() => setIsClearConfirmOpen(false)}
        onClear={handleClearHistory}
        isClearing={isClearing}
      />

      <OutOfCreditsDialog 
        isOpen={isOutOfCreditsDialogOpen}
        onClose={handleOutOfCreditsDialogClose}
        onPurchase={handlePurchaseCredits}
        onTopUp={handleTopUpPurchase}
        isSubscriptionActive={isSubscriptionActive}
      />

      <UndoDialog 
        isOpen={isUndoDialogOpen}
        onClose={() => setIsUndoDialogOpen(false)}
        onUndo={handleUndo}
      />
    </div>
  );
}

export default Chat;