import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import AppContent from './components/AppContent';
import LoadingSpinnerBig from './components/LoadingSpinnerBig';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import ContactUs from './components/ContactUs';
import NotFound from './components/NotFound';
import LandingPage from './components/LandingPage'; // Add this import
import { initializeAmplitude } from './lib/amplitude';
import './globals.css';
import { ToastProvider } from './components/ui/use-toast';

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinnerBig />;
  }

  return user ? children : <Navigate to="/" />;
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add this line */}
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route 
        path="/app/*" 
        element={
          <PrivateRoute>
            <AppContent />
          </PrivateRoute>
        } 
      />
      <Route path="*" element={<NotFound />} /> {/* Add this line for the 404 page */}
    </Routes>
  );
}

function AppWithAuth() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initApp = async () => {
      // Initialize Amplitude
      initializeAmplitude(user?.id);
      
      // Simulate initial loading
      setTimeout(() => setIsLoading(false), 1000);
    };

    initApp();
  }, [user]);

  if (isLoading) {
    return <LoadingSpinnerBig />;
  }

  return <AppRoutes />;
}

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <Router>
          <AppWithAuth />
        </Router>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;